// This file can be replaced during build by using the `fileReplacements` array.
export const environment = {
    production: false,
    stripe_token: 'STRIPE_TOKEN',
    paypal_token: 'PAYPAL_TOKEN',
    loadGif: 'assets/images/loader.gif',

    loading: {
        spinner: 'circles',
        duration: 3000
    },

    // PARAMETROS POLLO REAL PRO
    firebase: {
        apiKey: "AIzaSyD-3nHztWa1m8qB8narom0G_0B16eAoags",
        authDomain: "ianos-app-sv-pollo-real-pro.firebaseapp.com",
        projectId: "ianos-app-sv-pollo-real-pro",
        storageBucket: "ianos-app-sv-pollo-real-pro.appspot.com",
        messagingSenderId: "820710339461",
        appId: "1:820710339461:web:e237b973381b183f4bcaec",
        measurementId: "G-EZLGHR2LQ9"
    },
    urlBase: 'https://us-central1-ianos-app-sv-pollo-real-pro.cloudfunctions.net/',
    apikey: 'd7793bd7128-7cdb17b10d866-4726-eac008c1',
    googleMapsKey: 'AIzaSyCDk6GYGJWDeVPbgqlfOKGSXZ-9-eXBpW4',
    urlBaseWompy: 'https://us-central1-ianos-app-sv-pollo-real-pro.cloudfunctions.net//Wompi',

    bannerDefault: 'https://firebasestorage.googleapis.com/v0/b/ianos-app-usa-sanmiguelres-pro.appspot.com/o/assets%2Fimages%2Fregister-screen%2Fianos-banner-default-_SANMIGUEL-1900x600px.jpg?alt=media&token=0783d158-679a-4b8a-ad20-ee622f9e71c2',

    // TWILIO CREDENCIALES IanOS DEV
    twilio: {
        TWILIO_SERVICE_ENDPOINT: 'https://verify.twilio.com/v2/Services/VA6f37e2f3e25ae98433a02926ec75c77a/Verifications',
        TWILIO_SERVICE_VERIFICATION_CHECK: `https://verify.twilio.com/v2/Services/VA6f37e2f3e25ae98433a02926ec75c77a/VerificationCheck`,
        TWILIO_AUTH_TOKEN: '13b6af0768f7255a066f3436df02f6ca',
        TWILIO_ACCOUNT_SID: 'ACb5f6530b25d1d3d182d2df34d1747117',
        TWILIO_SERVICE_TOKEN: 'VA6f37e2f3e25ae98433a02926ec75c77a',
        TWILIO_MESSAGING_SERVICE_SID: 'MG7302002067524cd572d66063ff774b61'
    },

    // CREDENCIALES DE PRO ITWC
    wompi: {
        grant_type: 'client_credentials', // Dejar siempre client_credentials.
        audience: 'wompi_api', // Dejar siempre "wompi_api"
        client_id: 'dc4c16b2-08d9-4478-9037-40a580004f6b', // El client id del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
        client_secret: '2c1c79a5-8315-4989-8ade-1469183c0810', //Es la llave secreta del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
    },

    countCharacter(string) {
        let limit = (this.width < 1560) ? 25 : 45;
        if (string.length > limit) {
            string = string.substring(0, limit) + '...';
        }
        return string;
    },

    breadcrumbDynamic(arrayBreadcrumb) {
        let sendBreadcrumbDynamic = [];
        arrayBreadcrumb.forEach(element => {
            if (element != '') {
                let info = {
                    'name': element.replaceAll('-', ' '),
                    'url': element,
                };
                sendBreadcrumbDynamic.push(info);
            }
        });
        return sendBreadcrumbDynamic;
    }
}
    ;

