import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
// import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { Product } from '../../shared/classes/product';
import { ProductService } from '../../shared/services/product.service';
import { OrderService } from '../../shared/services/order.service';
import { WompiService } from '../../services/wompi.service';
import { Router } from '@angular/router';
import { User } from '../../shared/classes/user';
import { AuthService } from '../../shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../services/http.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isPlatformBrowser } from '@angular/common';
import Swal from 'sweetalert2';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DataSharingService } from '../../services/data-sharing.service';
import { FirestoreService } from '../../services/firestore.service';
import { CommonService } from '../../services/common.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

declare var Stripe;

interface Country {
    name: string;
    alpha2Code: string;
    flag: string;
    callingCodes: string[];
}

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
    @ViewChild('pasarelaWompi') pasarelaWompi: TemplateRef<any>;

    user = new User();
    public checkoutForm: UntypedFormGroup;
    public products: Product[] = [];
    // public payPalConfig ? : IPayPalConfig;
    public payment: string = 'Wompi';
    public shipping: string = '';
    public amount: any;
    private data_wompi: any;
    public verify: boolean = false;
    private orderId: number | string = localStorage.getItem('orderId');
    private isLogged: boolean = false;
    methodShipping: any[] = [];
    methodInvoicing: any[] = [];
    sucursales: any[] = [];
    invoicing: string = '';
    sucursal: string = '';
    metodoPago: any[] = [];
    pago: string = '';
    urlPayment: SafeResourceUrl;
    isWompi: boolean = false;
    isCash: boolean = false;
    closeResult: string;
    txtMessage: string = '';
    mainStatusOrder: string = '';
    mainBadgeColor: string = '';
    finalBadgeColor: string = '';
    finalStatusOrder: string = '';
    txtSuccess: string = '';
    public include_tax: boolean | string = localStorage.getItem('include_service_fee') === 'true' ?? false;
    public include_service_fee: boolean | string = localStorage.getItem('include_service_fee') === 'true' ?? false;
    public view_tax: boolean | string = localStorage.getItem('view_tax') === 'true' ?? false;
    public unique_tax: boolean | string = localStorage.getItem('unique_tax') === 'true' ?? false;
    public isWallet: boolean | string = localStorage.getItem('isWallet') === 'true' ?? false;
    private service_fee_limit: number = Number(localStorage.getItem('service_fee_limit')) || 0;
    private service_fee_percent: number = Number(localStorage.getItem('service_fee_percent')) || 0;
    private tax: number = Number(localStorage.getItem('current_tax')) || 0;
    protected readonly getTax = getTax;
    protected readonly getSubtotal = getSubtotal;
    protected readonly serviceFee = serviceFee;
    protected readonly getNewTotal = getNewTotal;
    protected readonly usedCredits = usedCredits;
    private tax_business: number = 0;
    public total_wallet: number = 0;
    public used_credits: boolean = false;
    public total_credits_used: number = 0;
    public phone: string = '';
    public number_to_verify: string = '';
    public send_verification_code: boolean = false;
    public codeVerificationCheck: string = '';
    private TWILIO_SERVICE_ENDPOINT = environment.twilio.TWILIO_SERVICE_ENDPOINT;
    private TWILIO_SERVICE_VERIFICATION_CHECK = environment.twilio.TWILIO_SERVICE_VERIFICATION_CHECK;
    private TWILIO_AUTH_TOKEN = environment.twilio.TWILIO_AUTH_TOKEN;
    private TWILIO_ACCOUNT_SID = environment.twilio.TWILIO_ACCOUNT_SID;
    private MIN_PHONE_LENGTH = 8;

    countries: Country[] = [];
    countryPrefix: { [code: string]: string } = {};
    countryMask: { [code: string]: string } = {};
    code: string = '';
    selectedCountry: string = '503';
    private email: string = '';
    private cardElement;
    private stripe;
    private keyStripe: string = '';
    active_method_wompi: boolean = false;
    active_method_cash: boolean = false;
    active_method_card: boolean = false; // si es true es por stripe
    public isStripe: boolean = false;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private fb: UntypedFormBuilder,
        public productService: ProductService,
        public wompi: WompiService,
        private orderService: OrderService,
        private auth: AuthService,
        private toastrService: ToastrService,
        private service: HttpService,
        private modalService: NgbModal,
        private router: Router,
        private domSatizer: DomSanitizer,
        private dataSharingService: DataSharingService,
        private firestoreService: FirestoreService,
        private commonService: CommonService,
        private http: HttpClient,
        private elementRef: ElementRef,
    ) {
    }

    ngOnInit(): void {
        this.productService.updateCart();
        this.checkoutForm = this.fb.group({
            firstname: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
            lastname: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
            phone: ['', [Validators.required, Validators.pattern('[0-9]+')]],
            email: ['', [Validators.required, Validators.email]]
            // address: ['', [Validators.required, Validators.maxLength(50)]],
            // country: ['', Validators.required],
            // town: ['', Validators.required],
            // state: ['', Validators.required],
            // postalcode: ['', Validators.required]
        });
        this.getUser();
        this.socket();

        this.productService.cartItems.subscribe(response => {
            // console.log('cartItems', response)
            this.products = response;
        });
        this.getTotal.subscribe(amount => this.amount = Number(this.getNewTotal().toFixed(2)));
        // this.initConfig();

        this.service.sendGET_SettingsApp('General').subscribe(res => {
            //console.log('response General', res);
            this.metodoPago = res.method_payment;
            this.active_method_wompi = res.active_method_wompi;
            this.active_method_cash = res.active_method_cash;
            this.active_method_card = res.active_method_card;
        });
        this.service.sendGET_SettingsApp('Shipping').subscribe(res => {
            console.log('response shipping', res);
            this.methodShipping = res.method;
        });
        this.service.sendGET_SettingsApp('Invoicing').subscribe(res => {
            // console.log('response Invoicing', res);
            this.methodInvoicing = res.method;
        });
        // this.service.sendGET_SettingsApp('Payment').subscribe(res => {
        //     console.log('response Invoicing', res);
        //     this.metodoPago = res.method;
        // })
        this.service.sendGET_BusinessPlace().subscribe(res => {
            console.log('response sucursale', res);
            this.sucursales = res;
        });
        this.getDefaultCountry();
        this.getCountries();
        this.initializeStripeElements();
    }

    initializeStripeElements() {
        // Verifica si Stripe.js está cargado
        if (typeof Stripe !== 'undefined') {
            this.firestoreService.getOne('Settings', 'Payment').subscribe(res => {
                // res.stripe_mode_live = false;
                this.email = this.user.email;
                if (res['stripe_mode_live']) {
                    // console.log("stripe key: ", res.stripe_pk_live);
                    this.stripe = Stripe(res['stripe_pk_live']);
                    this.keyStripe = res['stripe_pk_live'];
                    // Aquí puedes utilizar stripe para inicializar Stripe Elements
                    const elements = this.stripe.elements();

                    this.cardElement = elements.create('card', {
                        hidePostalCode: true, // Ocultar el campo del código postal
                    });
                    this.cardElement.mount(this.elementRef.nativeElement.querySelector('#card-element'));
                } else {
                    // console.log("stripe key: ", res.stripe_pk_test);
                    this.stripe = Stripe(res['stripe_pk_test']);
                    this.keyStripe = res['stripe_pk_test'];
                    // Aquí puedes utilizar stripe para inicializar Stripe Elements
                    const elements = this.stripe.elements();

                    this.cardElement = elements.create('card', {
                        hidePostalCode: true, // Ocultar el campo del código postal
                    });
                    this.cardElement.mount(this.elementRef.nativeElement.querySelector('#card-element'));
                }
            })
        } else {
            // Stripe.js no está cargado; maneja este caso adecuadamente
            console.error('Stripe.js no está cargado.');
        }
    }

    async onPay() {
        try {
            if (!this.stripe) {
                console.error('Stripe no está inicializado.');
                return;
            }

            // Obtén el token de la tarjeta directamente aquí
            const { token, error } = await this.stripe.createToken(this.cardElement, {
                name: this.user.firstName + ' ' + this.user.lastName, // Nombre del titular
            });

            if (error) {
                console.error(error);
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.message,
                })
                // Maneja el error apropiadamente, muestra un mensaje al usuario, etc.
            } else {
                console.info('Token de stripe', token); // Aquí tienes tu token de Stripe


            }
        } catch (error) {
            console.error('Error al procesar el pago:', error);
            // Maneja el error apropiadamente, muestra un mensaje al usuario, etc.
            Swal.close();
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error,
            })
        }
    }

    async onPay2() {
        try {
            if (!this.stripe) {
                console.error('Stripe no está inicializado.');
                return;
            }
            Swal.fire({
                icon: 'info',
                title: 'Procesando pago, espere un momento por favor',
                showConfirmButton: false,
                backdrop: 'static',
                allowOutsideClick: false,
            });

            await this.verifyUid(); // Verificamos el pago wompi

            // Obtén el token de la tarjeta directamente aquí
            const { token, error } = await this.stripe.createToken(this.cardElement, {
                name: this.user.firstName + ' ' + this.user.lastName, // Nombre del titular
            });

            if (error) {
                console.error(error);
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.message,
                })
                // Maneja el error apropiadamente, muestra un mensaje al usuario, etc.
            } else {

                let products = '';
                let dataCreateOrder = {
                    product: this.products,
                    details: this.checkoutForm.value,
                    orderId: this.orderId,
                    amount: this.getNewTotal(),
                    subtotal: this.getSubtotal(),
                    tax: this.getTax(),
                    serviceFee: this.serviceFee(),
                    used_credits: this.total_credits_used,
                };
                let createOrder = this.orderService.createOrder(dataCreateOrder);

                createOrder.checkoutItems.product.forEach(element => {
                    products = products.concat(element.product_name + ' || ');
                });

                let payment_amount = this.getNewTotal();
                payment_amount = Number(payment_amount.toFixed(2));
                console.log('payment_amount', payment_amount);

                this.service.createCustomerPost({
                    email: this.user.email,
                    name: this.user.firstName + ' ' + this.user.lastName,
                    uid: this.user.uid,
                    id: token['id'],
                    card: token['card'],
                    created: token['created'],
                    payment_amount: payment_amount * 100,
                    currency: 'usd',
                    description: 'Compra Tienda en linea - ' + this.orderId,
                    metadata: {
                        user_uid: this.user.uid,
                        email: this.user.email,
                        items: products
                    },
                    time: new Date().getTime()
                }).then((res1: any) => {
                    console.log('res1 -------------------- ', res1)
                    if (res1.success) {
                        this.orderService.processOrder(this.user, 666);
                        Swal.close();
                        Swal.fire({
                            icon: 'success',
                            title: 'Listo',
                            text: 'Pago realizado',
                        })
                    } else {
                        console.error('error payment', res1);
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Se ha producido un error con su pago, por favor inténtelo de nuevo.',
                        })
                    }
                })

            }
        } catch (error) {
            console.error('Error al procesar el pago:', error);
            // Maneja el error apropiadamente, muestra un mensaje al usuario, etc.
            Swal.close();
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error,
            })
        }
    }

    public get getTotal(): Observable<number> {
        return this.productService.cartTotalAmount();
    }

    // Stripe Payment Gateway
    stripeCheckout() {
        // var handler = (<any>window).StripeCheckout.configure({
        //     key: environment.stripe_token, // publishble key
        //     locale: 'auto',
        //     token: (token: any) => {
        //         // You can access the token ID with `token.id`.
        //         // Get the token ID to your server-side code for use.
        //         this.orderService.createOrder(this.products, this.checkoutForm.value, token.id, this.amount);
        //     }
        // });
        // handler.open({
        //     name: 'Multikart',
        //     description: 'Online Fashion Store',
        //     amount: this.amount * 100
        // })
    }

    // Paypal Payment Gateway
    private initConfig(): void {
        // this.payPalConfig = {
        //     currency: this.productService.Currency.currency,
        //     clientId: environment.paypal_token,
        //     createOrderOnClient: (data) => < ICreateOrderRequest > {
        //       intent: 'CAPTURE',
        //       purchase_units: [{
        //           amount: {
        //             currency_code: this.productService.Currency.currency,
        //             value: this.amount,
        //             breakdown: {
        //                 item_total: {
        //                     currency_code: this.productService.Currency.currency,
        //                     value: this.amount
        //                 }
        //             }
        //           }
        //       }]
        //   },
        //     advanced: {
        //         commit: 'true'
        //     },
        //     style: {
        //         label: 'paypal',
        //         size:  'small', // small | medium | large | responsive
        //         shape: 'rect', // pill | rect
        //     },
        //     onApprove: (data, actions) => {
        //         this.orderService.createOrder(this.products, this.checkoutForm.value, data.orderID, this.getTotal);
        //         console.log('onApprove - transaction was approved, but not authorized', data, actions);
        //         actions.order.get().then(details => {
        //             console.log('onApprove - you can get full order details inside onApprove: ', details);
        //         });
        //     },
        //     onClientAuthorization: (data) => {
        //         console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        //     },
        //     onCancel: (data, actions) => {
        //         console.log('OnCancel', data, actions);
        //     },
        //     onError: err => {
        //         console.log('OnError', err);
        //     },
        //     onClick: (data, actions) => {
        //         console.log('onClick', data, actions);
        //     }
        // };
    }

    pagoWompi(content) {
        this.verifyForm().then(async res => {
            Swal.fire({
                icon: 'info',
                title: 'Procesando pago, espere un momento por favor',
                showConfirmButton: false,
                backdrop: 'static',
                allowOutsideClick: false,
            });

            await this.updateNameUser();

            await this.verifyUid(); // Verificamos el pago wompi

            let products = '';
            let dataCreateOrder = {
                product: this.products,
                details: this.checkoutForm.value,
                orderId: this.orderId,
                amount: this.getNewTotal(),
                subtotal: this.getSubtotal(),
                tax: this.getTax(),
                serviceFee: this.serviceFee(),
                used_credits: this.total_credits_used,
            };
            let createOrder = this.orderService.createOrder(dataCreateOrder);
            console.log('createOrder', createOrder);

            createOrder.checkoutItems.product.forEach(element => {
                products = products.concat(element.product_name + ' || ');
            });

            let data = {
                token: '',
                identificadorEnlaceComercio: 'Pedido # ' + this.orderId,
                monto: createOrder.checkoutItems.totalAmount,
                // monto: this.getNewTotal(),
                nombreProducto: products,
                configuracion: {
                    esMontoEditable: false,
                    // urlRedirect: 'http://localhost:8100/checkout-details/'+code,
                    // urlRetorno: 'http://localhost:8100/checkout-details/',
                    esCantidadEditable: false,
                    duracionInterfazIntentoMinutos: 30,
                    emailsNotificacion: createOrder.checkoutItems.shippingDetails.email,
                    notificarTransaccionCliente: true
                }
            };

            /*this.wompi.wompiApiAuthentication().then((toke: any) => {
                console.log("wompiApiAuthentication: ", toke);
                data.token = toke.access_token;
                this.wompi.EnlacePago(data).then((res: any) => {
                    console.log("EnlacePago: ", res.body);
                    let data_wompi = res.body;
                    this.data_wompi = data_wompi;
                    // this.data_wompi.token = toke.access_token;
                    // window.location.href = data_wompi.urlPayment;
                    window.open(data_wompi.urlPayment, '_blank');
                    // console.log("data_wompi: ", this.data_wompi);
                    this.verify = true;
                });
            }).catch((error) => {
                console.log('error send data', error);
            });*/

            this.wompi.wompiApiAuthentication().then((toke: any) => {
                // console.log('response wompiApiAuthentication: ', toke);
                data.token = toke.access_token;
                this.wompi.EnlacePago(data).then((res: any) => {
                    // console.log('response EnlacePago: ', res);
                    if (res.success) {
                        this.data_wompi = res;
                        this.data_wompi.access_token = toke.access_token;
                        this.verify = true;
                        this.urlPayment = this.domSatizer.bypassSecurityTrustResourceUrl(res.urlPayment);
                        // window.open(res.urlPayment, '_blank');
                        this.orderService.processOrder(this.user, 666, this.data_wompi);
                        Swal.close();
                        this.openVerticallyCentered(content);
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Ha ocurrido un error a la hora de generar el pago, intentelo de nuevo por favor',
                        });
                    }
                });
            }).catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Ha ocurrido un error a la hora de procesar el pago, intentelo de nuevo por favor',
                });
            });
        }).catch(err => {
            Swal.fire('Atención!', err, 'info');
        });
    }

    // Autor: Leo | Date: 16-11-2023
    updateNameUser() {
        let message_post = {
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            current_name: this.user.firstName + ' ' + this.user.lastName,
        };
        this.updateUser(message_post, this.user.uid).then(res => {
            console.info('user update');
        }).catch(err => {
            console.error('error userß update');
        });
    }

    confirmParymentWompi() {
        Swal.fire({
            icon: 'info',
            title: 'Confirmando pago, espere un momento por favor',
            showConfirmButton: false,
            backdrop: 'static',
            allowOutsideClick: false,
        });
        let data = {
            id: this.data_wompi.idLink,
            token: this.data_wompi.access_token,
        };
        console.log('data: confirm ', data);
        this.wompi.EnlacePagoId(data).then((confirm: any) => {
            console.log('EnlacePagoId: ', confirm);
            if (confirm.estaProductivo) {
                if (confirm.esAprobada) {
                    Swal.fire('Exito', 'Pago procesado correctamente', 'success').then(res => {
                        localStorage.removeItem('cartItems');
                        localStorage.removeItem('orderId');
                        // localStorage.removeItem("order_uid");
                        // this.router.navigate(['/shop/checkout/success', this.orderId]);
                        window.location.href = '/shop/checkout/success/' + localStorage.getItem('order_uid');
                    });
                } else {
                    Swal.fire('Error', 'No hemos podido verificar el pago real realizado en la pasarela de pago.', 'error');
                }
            } else {
                Swal.fire('Exito', 'Pago procesado correctamente', 'success').then(res => {
                    localStorage.removeItem('cartItems');
                    localStorage.removeItem('orderId');
                    // localStorage.removeItem("order_uid");
                    // this.router.navigate(['/shop/checkout/success', this.orderId]);
                    window.location.href = '/shop/checkout/success/' + localStorage.getItem('order_uid');
                });
            }
        });
    }

    metodoEnvio(event) {
        console.log('evento', event);
        localStorage.setItem('shipping', event);
    }

    documentoGenerar(event) {
        console.log('evento', event);
        localStorage.setItem('invoicing', event);
    }

    sucursalRetiro(event) {
        console.log('evento', event);
        let [select] = this.sucursales.filter(item => item.company_dba_name == event);
        console.log('select sucursales', select);
        if (select) {
            this.tax_business = select.tax;
            localStorage.setItem('sucursal', event);
            localStorage.setItem('commerce_direction', select.street_address);
        }

    }

    paymentMethod(event) {
        console.log('evento', event);
        let [select] = this.metodoPago.filter(item => item.name == event);
        this.isWompi = select?.isWompi;
        this.isCash = select?.isCash;
        this.isStripe = select?.isStripe;
        this.txtMessage = select?.txtMessage;
        this.mainStatusOrder = select?.mainStatusOrder;
        this.mainBadgeColor = select?.mainBadgeColor;
        this.finalBadgeColor = select?.finalBadgeColor;
        this.finalStatusOrder = select?.finalStatusOrder;
        this.txtSuccess = select?.txtSuccess;
        console.log('select payment', select);
        let txtPayment = this.isWompi ? 'Wompi' : event;
        localStorage.setItem('mainBadgeColor', this.mainBadgeColor);
        localStorage.setItem('mainStatusOrder', this.mainStatusOrder);
        localStorage.setItem('finalStatusOrder', this.finalStatusOrder);
        localStorage.setItem('finalBadgeColor', this.finalBadgeColor);
        localStorage.setItem('txtSuccess', this.txtSuccess);
        localStorage.setItem('payment', txtPayment);
    }

    async confirmarPedido() {
        this.verifyForm().then(async res => {

            await this.updateNameUser();
            await this.verifyUid();

            let templateContent;
            templateContent = document.getElementById('table-invoice').innerHTML;
            localStorage.setItem('templateContent', templateContent);
            Swal.fire({
                icon: 'info',
                title: 'Procesando pedido, espere un momento por favor',
                showConfirmButton: false,
                backdrop: 'static',
                allowOutsideClick: false,
            });
            let dataCreateOrder = {
                product: this.products,
                details: this.checkoutForm.value,
                orderId: this.orderId,
                amount: this.getNewTotal(),
                tax: this.getTax(),
                subtotal: this.getSubtotal(),
                serviceFee: this.serviceFee(),
                used_credits: this.total_credits_used,
            };
            this.orderService.createOrder(dataCreateOrder);
            this.orderService.processOrder(this.user, 666);
        }).catch(err => {
            Swal.fire('Atención!', err, 'info');
        });
    }

    verifyForm() {
        return new Promise((resolve, reject) => {
            if (this.shipping === '' || this.invoicing === '' || this.sucursal === '' || this.pago === '' || !this.user.verificate_phone || this.user.firstName.length < 3 || this.user.lastName.length < 3) {
                let txt = 'Los siguientes campos son requeridos: ';
                if (this.shipping === '') {
                    txt += 'Método de envío, ';
                }
                if (this.invoicing === '') {
                    txt += 'Documento a generar, ';
                }
                if (this.sucursal === '') {
                    txt += 'Sucursal de retiro, ';
                }
                if (!this.user.verificate_phone) {
                    txt += 'Número de télefono validado, ';
                }
                if (this.user.firstName.length < 3) {
                    txt += 'Nombre debe contener al menos 3 caracteres, ';
                }
                if (this.user.lastName.length < 3) {
                    txt += 'Apellido debe contener al menos 3 caracteres, ';
                }
                if (this.pago === '') {
                    txt += 'Método de pago, ';
                }
                txt = txt.slice(0, -2); // Eliminar la última coma y el espacio
                reject(txt);
            } else {
                resolve(777);
            }
        });
    }

    openModal() {
        if (isPlatformBrowser(this.platformId)) { // For SSR
            this.modalService.open(this.pasarelaWompi, {
                size: 'md',
                backdrop: 'static',
                keyboard: false,
                centered: true,
                windowClass: 'bd-example-modal-md theme-modal agem'
            }).result.then((result) => {
                `Result ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }

    openVerticallyCentered(content) {
        this.modalService.open(content, {
            centered: true,
            size: 'xl',
            backdrop: 'static',
            keyboard: false,
            windowClass: 'modal-2',
            animation: true
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            console.log('close modal by pressing ESC');
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            console.log('close modal by clicking on a backdrop');
            return 'by clicking on a backdrop';
        } else {
            console.log(`close modal with: ${reason}`);
            return `with: ${reason}`;
        }
    }

    cancelPaymentWompi() {
        let order_uid = localStorage.getItem('order_uid');
        console.log('order_uid', order_uid);
        if (order_uid != '') {
            this.service.getOneOrder(order_uid).subscribe(res => {
                console.log('response getOneOrder', res);
                let message = res;
                message.orders_status_uid = 'Payment Failed';
                message.badgeColor = 'danger';
                this.service.updateOrder(message, message.id).then(res1 => {
                    this.modalService.dismissAll('Se arrepintio');
                    // localStorage.removeItem('order_uid');
                    this.getStock(message).then(stock => {
                        setTimeout(() => {
                            console.log('update: ', stock);
                            this.updateStock(stock).then(res2 => {
                                console.log('update: ', stock);
                            });
                        }, 1e3);
                    });

                    this.router.navigate(['/shop/cart']);
                    console.info('update order', res1);
                }).catch(err => {
                    console.error('error update order', err);
                });
            });
        }
    }

    getStock(order) {
        let update = [];
        let new_products_stock;
        return new Promise((resolve, reject) => {
            order.items.forEach(element => {
                this.service.getOneProduct(element.uid).subscribe(res => {
                    console.log('sendGET_One_Product: ', res.products_stock, ' + ', res.product_name);
                    new_products_stock = res.products_stock + element.quantity;
                    res.products_stock = new_products_stock;
                    if (new_products_stock > 0) {
                        update.push(res);
                    } else {
                        res.product_availability = 'Stock';
                        res.product_background_availability = 'success';
                        update.push(res);
                    }
                });
            });
            resolve(update);
        });
    }

    updateStockOld(stock) {
        return new Promise((resolve, reject) => {
            let i = 1;
            let x = stock.length;
            stock.forEach(element => {
                this.service.updateProduct(element, element.id).then(res => {
                    if (x == i) {
                        window.location.href = '/shop/cart';
                    }
                    i++;
                });
            });
            resolve(stock);
        });
    }

    async updateStock(stock) {
        try {
            let x = 1;
            for (const element of stock) {

                let clonedObject = { ...element };

                if ('old_products_stock' in element) {
                    delete element.old_products_stock;
                }

                let messagePost = {
                    products_stock: element.products_stock,
                }

                let dataProduct = clonedObject;

                let dataLog = {
                    reason: 'Update product - Checkout ' + this.orderId,
                    user_uid: this.user.uid,
                    name_user: this.user.firstName + ' ' + this.user.lastName,
                    date: Date.now(),
                    from: 'Tienda Online',
                    product_uid: element.id,
                    data_send: element,
                    quantity_before: dataProduct.products_stock || 0,
                    quantity_after: element.products_stock || 0.00,
                    price_before: dataProduct.price_tax_product || 0,
                    price_after: dataProduct.price_tax_product || 0,
                    discount_before: dataProduct.discount || 0,
                    discount_after: dataProduct.discount || 0,
                };

                let log = {
                    info: dataLog,
                    product_uid: element.id
                }

                console.time('save log');
                await this.productService.saveLog(log);
                console.timeEnd('save log');

                await this.service.sendPUT_Product(messagePost, element.id);

                x++;
            }
            window.location.href = '/shop/cart';
            return stock;
        } catch (error) {
            console.error("Error al actualizar el stock:", error);
            throw error; // Puedes volver a lanzar el error si lo deseas
        }
    }

    socket() {
        this.dataSharingService.include_service_fee.subscribe((valor: boolean | string) => {
            this.include_service_fee = valor;
        });
        this.dataSharingService.include_tax.subscribe((valor: boolean | string) => {
            this.include_tax = valor;
        });
        this.dataSharingService.service_fee_percent.subscribe((valor: number) => {
            this.service_fee_percent = valor;
        });
        this.dataSharingService.service_fee_limit.subscribe((valor: number) => {
            this.service_fee_limit = valor;
        });
        this.dataSharingService.currentTaxSubject.subscribe((valor: number) => {
            this.tax = valor;
        });
        this.dataSharingService.view_tax.subscribe((valor: boolean) => {
            this.view_tax = valor;
        });
        this.dataSharingService.unique_tax.subscribe((valor: boolean) => {
            this.unique_tax = valor;
        });
    }

    getUser() {
        this.auth.getUser2().then(async userLocal => {
            this.isLogged = true;
            this.user = userLocal;
            // console.log("this.user.emailVerified: ", this.user.emailVerified);
            this.phone = (userLocal.phone && userLocal.phone['nationalNumber']) || '';
            if (!this.user.emailVerified) {
                this.getUserFirebase();
            }

            this.checkoutForm.value.firstname = this.user.firstName;
            this.checkoutForm.value.lastname = this.user.lastName;
            // @ts-ignore
            this.checkoutForm.value.phone = this.user.phone?.internationalNumber || this.user.phone;
            this.checkoutForm.value.email = this.user.email;

            this.verifyWallet();
            // console.log("this.checkoutForm.value: ", this.checkoutForm.value);
        }).catch(e => {
            console.log('no hay sesión', e);
            this.router.navigate(['/pages/login']).then(() => this.toastrService.info('Estimado usuario, es necesario iniciar sesión para continuar con el proceso de compra.'));
        });
    }

    verifyWallet() {
        if (this.isWallet) {
            this.firestoreService.getWhere('Wallet', 'uid', this.user.uid).subscribe(res => {
                console.log('response wallet', res);
                if (res.length > 0) {
                    this.total_wallet = res[0].total;
                }
            });
        }
    }

    async sendVerificationCode(phone) {
        const tel = phone.toString().replaceAll(' ', '');
        const number = `+${this.code}${tel}`;

        if (tel.length < this.MIN_PHONE_LENGTH) {
            throw new Error('Invalid phone number. Please enter a valid phone number.');
        }

        const data = new URLSearchParams();
        data.append('To', number);
        data.append('Channel', 'sms');

        try {
            const response = await fetch(this.TWILIO_SERVICE_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Basic ${btoa(`${this.TWILIO_ACCOUNT_SID}:${this.TWILIO_AUTH_TOKEN}`)}`
                },
                body: data.toString()
            });

            if (!response.ok) {
                throw new Error('Failed to send verification code. Please try again later.');
            } else {
                Swal.fire('Success', 'El código ha sido enviado correctamente, por favor ingrese el código que ha sido enviado a su número, para poder validarlo en nuestro sistema.', 'success');
                console.log('response', response);
                this.send_verification_code = true;
                this.number_to_verify = number;
            }

        } catch (error) {
            console.error(error);
            throw new Error('Failed to send verification code. Please try again later.');
        }
    }

    checkVerificationCode() {
        let code = this.codeVerificationCheck.toString();

        const body = new URLSearchParams();
        body.set('To', this.number_to_verify);
        body.set('Code', code);

        fetch(this.TWILIO_SERVICE_VERIFICATION_CHECK, {
            method: 'POST',
            headers: {
                'Authorization': `Basic ${btoa(`${this.TWILIO_ACCOUNT_SID}:${this.TWILIO_AUTH_TOKEN}`)}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: body.toString()
        })
            .then(response => response.json())
            .then(data => {
                if (data.status == 'approved' && data.valid) {
                    let formattedNumber = this.commonService.formattedNumber(this.phone);
                    this.user.verificate_phone = true;
                    let message_post = {
                        verificate_phone: true,
                        date_verify: Date.now(),
                        user_uid_verify: this.user.uid,
                        name_user_verify: this.user.firstName,
                        verification_code: code,
                        phone: {
                            isoCode: 'sv',
                            internationalNumber: `+${this.code} ${formattedNumber}`,
                            dialCode: `+${this.code}`,
                            nationalNumber: formattedNumber
                        }
                    };
                    this.updateUser(message_post, this.user.uid).then(res => {
                        this.commonService.alertModal('Número de teléfono', 2);
                    }).catch(err => {
                        this.commonService.alertModal('Número de teléfono', 3);
                    });
                } else {
                    this.commonService.alertModal('Número de teléfono', 3);
                }
            })
            .catch(error => {
                console.error(error);
                this.commonService.alertModal('Número de teléfono', 3);
            });
    }

    getUserFirebase() {
        this.auth.getUserFirebase()
            .then((res) => {
                if (res['emailVerified'] === true) {
                    this.updateUserEmailVerified(this.user.uid)
                        .then(() => {
                            console.log('User updated successfully.');
                        })
                        .catch(() => {
                            this.redirectToLogin();
                        });
                } else {
                    this.redirectToLogin();
                }
            })
            .catch(() => {
                this.redirectToLogin();
            });
    }

    updateUserEmailVerified(uid) {
        const messagePost = {
            emailVerified: true
        };
        return this.updateUser(messagePost, uid);
    }

    redirectToLogin() {
        this.auth.logout().then((e) => {
            this.router.navigate(['/pages/login']).then(() => {
                Swal.fire(
                    'Estimado usuario',
                    'Por motivos de seguridad, es necesario validar su cuenta para poder continuar con el proceso de compra, ' +
                    'se le ha enviado un correo electronico para validar su cuenta, favor de verificar e inciar sesión nuevamente',
                    'info'
                );
                this.auth.sendEmailVerification().then(r => console.log('then sendEmailVerification', r)).catch(err => console.error('catch sendEmailVerification', err));
            });
        }).catch((e) => {
            console.log('catch signOut', e);
        });
    }

    updateUser(message, uid) {
        return new Promise((resolve, reject) => {
            this.service.updateUser(message, uid).subscribe(
                response => {
                    resolve(response);
                },
                error => {
                    reject(error);
                }
            );
        });
    }

    getDefaultCountry() {
        this.http.get<any>('https://ipapi.co/json/').subscribe(
            response => {
                const userCountryCode = response.country_calling_code.replace('+', '');
                this.selectedCountry = userCountryCode;
                console.log('userCountryCode', response);
                this.phone = this.countryPrefix[userCountryCode];
                this.code = userCountryCode;
            }
        );
    }

    setDefaultCountry() {
        // aquí puedes implementar la lógica para detectar el país por defecto del usuario, utilizando la IP o cualquier otro método
        // por ahora, simplemente establecemos El Salvador como país por defecto
        // this.selectedCountry = 'SV';
    }

    getCountries() {
        this.http.get<Country[]>('https://restcountries.com/v2/all').subscribe(
            response => {
                this.countries = response;
                this.countries.forEach(country => {
                    this.countryPrefix[country.alpha2Code] = '+' + country.callingCodes[0];
                    this.countryMask[country.alpha2Code] = '0'.repeat(country.callingCodes[0].length);
                });
                this.setDefaultCountry();
            },
            error => {
                console.log(error);
            }
        );
    }

    onCountryChange() {
        this.code = this.countryPrefix[this.selectedCountry];
        console.log(this.countries.find(item => item.callingCodes[0]));
    }

    // verifyOrder_(uid) {
    //     this.firestoreService.getWhere('Orders', 'uid', uid).subscribe(async res => {
    //         let [order] = res;
    //         console.log('response 1', order);
    //
    //         if (!order) {
    //             return;
    //         }
    //
    //
    //         if (order.orders_status_uid == 'Payment Request' && order.waytopay == "Wompi" && order.data_wompi) {
    //             const data = {
    //                 id: order.data_wompi.idLink,
    //                 token: order.data_wompi.access_token,
    //             };
    //
    //             try {
    //                 const wompiResponse = await this.verifyPaymentWompi(data);
    //
    //                 const messagePost = {
    //                     orders_status_uid: 'New',
    //                     badgeColor: 'primary',
    //                     wompy_transaction: wompiResponse
    //                 };
    //
    //                 try {
    //                     const response = await this.service.updateOrder(messagePost, order.id);
    //                     console.info('se actualizo order', response);
    //
    //                     for (const element of order.items) {
    //                         const dataProduct = {
    //                             product_uid: element.uid,
    //                             quantity: element.quantity
    //                         };
    //                         const dataUpdate = await this.getStockProduct(dataProduct);
    //                         await this.updateStockProduct(dataUpdate, element.uid);
    //                     }
    //                 } catch (errs) {
    //                     // console.error('error actualizar orden', errs);
    //                 }
    //             } catch (err) {
    //                 // console.info('pago no ha sido procesado por wompi', err);
    //                 this.orderId = Date.now().toString();
    //                 localStorage.setItem('orderId', this.orderId.toString());
    //                 localStorage.removeItem('order_uid');
    //             }
    //         } else {
    //             this.orderId = Date.now().toString();
    //             localStorage.setItem('orderId', this.orderId.toString());
    //             localStorage.removeItem('order_uid');
    //         }
    //     });
    //
    //
    //
    //     // this.service.getOrderByUid(uid).subscribe(res => {
    //     //     console.log('getOrderByUid', res);
    //     //     if (res.success == true) {
    //     //         let order = res;
    //     //         if (order.orders_status_uid == 'Payment Request') {
    //     //             if (order.data_wompi != undefined) {
    //     //                 let data = {
    //     //                     id: order.data_wompi.idLink,
    //     //                     token: order.data_wompi.access_token,
    //     //                 };
    //     //                 this.verifyPaymentWompi(data).then(res => {
    //     //                     let messagePost = {
    //     //                         orders_status_uid: 'New',
    //     //                         badgeColor: 'primary',
    //     //                         wompy_transaction: res
    //     //                     };
    //     //                     this.service.updateOrder(messagePost, order.id).then(async response => {
    //     //                         console.info('se actualizo order', response);
    //     //                         for (const element of order.items) {
    //     //                             let dataProduct = {
    //     //                                 product_uid: element.uid,
    //     //                                 quantity: element.quantity
    //     //                             };
    //     //                             let dataUpdate = await this.getStockProduct(dataProduct);
    //     //                             await this.updateStockProduct(dataUpdate, element.uid);
    //     //                         }
    //     //                     }).catch(errs => {
    //     //                         // console.error('error actualizar orden', errs);
    //     //                     });
    //     //                     // console.info('pago ha sido procesado por wompi', res);
    //     //                 }).catch(err => {
    //     //                     // console.info('pago no ha sido procesado por wompi', err);
    //     //                 });
    //     //             } else {
    //     //                 console.log('no es un orden wompi')
    //     //             }
    //     //         } else { // creamos nuevo orderId y eliminamos el order_uid que tenemos en el localstorage
    //     //             this.orderId = Date.now().toString();
    //     //             localStorage.setItem('orderId', this.orderId.toString());
    //     //             localStorage.removeItem('order_uid');
    //     //         }
    //     //     }
    //     // });
    // }

    verifyPaymentWompi(data) {
        return new Promise((resolve, reject) => {
            this.wompi.EnlacePagoId(data).then((confirm: any) => {
                if (confirm != '' || confirm != null) {
                    if (confirm.cantidadPagosExitosos) {
                        resolve(true);
                        console.log('cantidad pagos existos', confirm.cantidadPagosExitosos);
                    } else {
                        console.error('no hay cantidad pagos existos', confirm.cantidadPagosExitosos)
                        reject(false);
                        reject(false); // cuando el aplicativo esta en modo prueba
                    }
                } else {
                    reject(false);
                }
            }).catch(err => {
                console.log('catch verifyPaymentWompi', err)
                reject(false);
            });
        });
    }

    getStockProduct(data) {
        return new Promise((resolve, reject) => {
            let message_post = {
                products_stock: 0,
                product_availability: '',
                product_background_availability: '',
            };
            let new_stock = 0;
            this.service.getOneProduct(data.product_uid).subscribe(res => {
                new_stock = res.products_stock - data.quantity;
                message_post['old_products_stock'] = res.products_stock;
                message_post.products_stock = new_stock;
                message_post.product_availability = new_stock > 0 ? 'Out of stock' : 'Stock';
                message_post.product_background_availability = new_stock > 0 ? 'success' : 'danger';
                resolve(message_post);
            });
        });
    }

    updateStockProduct(data, product_uid) {
        return new Promise((resolve, reject) => {
            this.service.updateProduct(data, product_uid).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        });
    }

    async verifyUid() {
        let uid = localStorage.getItem('orderId');
        if (uid == '' || uid == undefined || uid == 'null') {
            this.orderId = Date.now().toString();
            localStorage.setItem('orderId', Date.now().toString());
            console.log('entro', this.orderId)
        } else {
            console.log('entro 2', this.orderId)
            await this.verifyOrder(uid);
        }
    }

    // Este método maneja la lógica de procesar una orden en función de su estado y método de pago.
    async verifyOrder(uid: string) {
        // Obtenemos la orden
        this.service.getOrderByUid(uid).subscribe(res => {
            if (res.success != true) {
                console.log('No se encontraron órdenes con el UID proporcionado.');
                return;
            }

            const order = res; // Tomamos la orden

            console.log('Orden encontrada:', order);

            // Verificamos si el estado de la orden es 'Payment Request'.
            if (order.orders_status_uid === 'Payment Request') {
                // Verificamos si el método de pago es 'Wompi'.
                if (order.waytopay === 'Wompi') {
                    console.log('es de Wompi.');
                    /**
                     * verificamos si tiene info en data_wompi,
                     * de no ser asi quiere decir que es una orden de wompi vieja en la cual no se habia implementado lo de guardar el campo data_wompi
                     */
                    if (order.data_wompi) {
                        // Obtenemos los datos necesarios para verificar el pago en Wompi.
                        const data = {
                            id: order.data_wompi.idLink,
                            token: order.data_wompi.access_token,
                        };
                        console.log('tiene data wompi');

                        // Verificamos el pago en Wompi.
                        this.verifyPaymentWompi(data)
                            .then((wompiResponse) => {
                                console.log('verifyPaymentWompi', wompiResponse);

                                // Actualizamos el estado de la orden y otros datos relacionados.
                                const messagePost = {
                                    orders_status_uid: 'New',
                                    badgeColor: 'primary',
                                    wompy_transaction: wompiResponse,
                                };

                                this.service.updateOrder(messagePost, order.id)
                                    .then(async (response) => {
                                        console.info('Orden actualizada:', response);

                                        // Actualizamos el log de los productos en la orden.
                                        for (const element of order.items) {
                                            const dataProduct2 = {
                                                product_uid: element.uid,
                                                quantity: element.quantity,
                                            };

                                            let dataUpdate = await this.getStockProduct(dataProduct2);
                                            // @ts-ignore
                                            let clonedObject = { ...dataUpdate };
                                            let messagePost = {
                                                products_stock: dataUpdate['products_stock'],
                                            }

                                            let dataProduct = clonedObject;

                                            let dataLog = {
                                                reason: 'Update product - Wompi ' + this.orderId,
                                                user_uid: this.user.uid,
                                                name_user: this.user.firstName + ' ' + this.user.lastName,
                                                date: Date.now(),
                                                from: 'Tienda Online',
                                                product_uid: element.id,
                                                data_send: element,
                                                quantity_before: dataProduct.products_stock,
                                                quantity_after: element.products_stock,
                                                price_before: dataProduct.old_products_stock,
                                                price_after: dataProduct.price_tax_product,
                                                discount_before: dataProduct.discount,
                                                discount_after: dataProduct.discount,
                                            };

                                            let log = {
                                                info: dataLog,
                                                product_uid: element.id
                                            }

                                            console.time('save log');
                                            await this.productService.saveLog(log);
                                            console.timeEnd('save log');

                                            // await this.updateStockProduct(messagePost, element.uid);
                                        }

                                        // Actualizamos el stock de los productos en la orden.
                                        this.service.sendPUT_StockProducts(order.items).then((res2: any) => {
                                            // console.log("sendPUT_StockProducts: ", res2);
                                        });
                                    })
                                    .catch((errs) => {
                                        console.error('Error al actualizar la orden:', errs);
                                    });

                                // Creamos un nuevo orderId y eliminamos el order_uid del local storage.
                                this.createAndResetOrderId();
                            })
                            .catch((err) => {
                                console.error('Pago no ha sido procesado por Wompi:', err);

                                // Creamos un nuevo orderId y eliminamos el order_uid del local storage.
                                this.createAndResetOrderId();
                            });
                    } else {
                        console.log('no tiene data_wompi');

                        // Creamos un nuevo orderId y eliminamos el order_uid del local storage.
                        this.createAndResetOrderId();

                    }
                } else {
                    console.log('No es una orden de Wompi.');
                    // Creamos un nuevo orderId y eliminamos el order_uid del local storage.
                    this.createAndResetOrderId();
                }
            } else {
                // Creamos un nuevo orderId y eliminamos el order_uid del local storage.
                this.createAndResetOrderId();
            }
        });
    }

    // Este método crea un nuevo orderId y elimina el order_uid del local storage.
    createAndResetOrderId() {
        this.orderId = Date.now().toString();
        localStorage.setItem('orderId', this.orderId);
        localStorage.removeItem('order_uid');
    }

}

/**
 * Retorna el impuesto total basado en el subtotal, la tasa de impuestos y las opciones de impuestos de la transacción.
 * Si la opción include_tax es verdadera, el impuesto se calcula a partir del subtotal sin impuestos.
 * Si la opción include_tax es falsa, el impuesto se calcula a partir del subtotal con impuestos.
 * @returns el impuesto total
 */
export function getTax() {
    // Asignamos el porcentaje de impuestos y el valor inicial de la variable 'tax'
    let tax_percent = this.unique_tax ? this.tax : this.tax_business;
    let tax = 0;

    // Si 'view_tax' es verdadero, realizamos los cálculos de impuestos
    if (this.view_tax) {
        // Si 'include_tax' es verdadero, calculamos el impuesto restando el subtotal sin impuestos del subtotal completo
        // Si no, calculamos el impuesto como el subtotal multiplicado por el porcentaje de impuestos
        this.getTotal.subscribe(subtotal => {
            tax = this.include_tax
                ? subtotal - (subtotal / (1 + tax_percent))
                : subtotal * tax_percent;
        });
    }

    return tax;
}

export function getSubtotal() {
    // Obtener el porcentaje de impuestos y establecer el subtotal inicial en 0.
    let tax_percent = this.unique_tax ? this.tax : this.tax_business;
    let subtotal: number = 0.00;

    // Suscribirse al observable de total y calcular el subtotal.
    this.getTotal.subscribe(total => {
        // Si se incluye el impuesto, calcular el subtotal sin impuestos.
        subtotal = this.include_tax && this.view_tax ? total / (1 + tax_percent) : total;
    });

    // Devolver el subtotal actual.
    return subtotal;
}

export function serviceFee(): number {
    // Inicializar el cargo por servicio y obtener el subtotal.
    let service_fee: number = 0;
    let subtotal = this.getSubtotal();

    // Si no se incluye el cargo por servicio, calcularlo.
    service_fee = this.include_service_fee
        ? 0
        : subtotal * this.service_fee_percent;

    // Devolver el cargo por servicio actual.
    return service_fee;
}

function usedCredits(): number {
    return this.used_credits ? this.total_wallet : 0;
}

/**
 * Calcula el nuevo total de una transacción, considerando si se incluyen o no el cargo de servicio y el impuesto.
 *
 * @returns El nuevo total de la transacción.
 */
export function getNewTotal(): number {
    let newTotal = 0.00;
    let subtotal = this.getSubtotal();
    let serviceFee = this.serviceFee();
    let tax = this.getTax();
    let credits = this.usedCredits();

    // Si se incluye el cargo de servicio, se calcula el nuevo total en base a si se incluye o no el impuesto.
    if (this.include_service_fee) {
        if (this.include_tax) {
            // Si se incluye el impuesto, se agrega el subtotal más el impuesto al nuevo total.
            if (this.view_tax) {
                newTotal = subtotal + tax;
            } else {
                newTotal = subtotal;
            }
        } else {
            // Si no se incluye el impuesto, se agrega el subtotal más el impuesto calculado al nuevo total.
            newTotal = subtotal + tax;
        }
    } else {
        // Si no se incluye el cargo de servicio, se calcula el nuevo total en base a si se incluye o no el impuesto.
        if (this.include_tax) {
            // Si se incluye el impuesto, se agrega el subtotal más el impuesto al nuevo total.
            if (this.view_tax) {
                newTotal = subtotal + tax + serviceFee;
            } else {
                // Si se incluye el impuesto, se agrega el subtotal más el cargo de servicio al nuevo total.
                newTotal = subtotal + serviceFee;
            }
        } else {
            // Si no se incluye el impuesto, se agrega el subtotal más el cargo de servicio y el impuesto calculado al nuevo total.
            newTotal = subtotal + serviceFee + tax;
        }
    }

    // Se comprueba si hay créditos utilizados
    if (this.used_credits) {
        // Si la cantidad de créditos es mayor que el nuevo total, entonces el nuevo total es 0
        if (credits > newTotal) {
            this.total_credits_used = newTotal;
            newTotal = 0;
        } else { // De lo contrario, se resta la cantidad de créditos del nuevo total
            newTotal = newTotal - credits;
            this.total_credits_used = credits;
        }
    }


    // Se devuelve el nuevo total de la transacción.
    return newTotal;
}


